































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { addYears, format, subDays } from '@/filter/dateFormat';
import DatePicker from '@/components/form/DatePicker.vue';
import { CreateAnnualAccounting, ListOfAssets } from '../../../types';
import { CREATE_ANNUAL_ACCOUNTING, FETCH_ACCOUNTING } from '@/modules/listOfAssets/store';
import { ApiResponse } from '@/components/types';
import uuid from 'uuid/v4';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    DatePicker
  }
})
export default class CreateAnnualAccountingDialog extends Vue {
  @Prop({ type: Object }) listOfAssets!: ListOfAssets;
  @Prop({ type: String }) start!: string;

  @ListOfAssets.Action(CREATE_ANNUAL_ACCOUNTING) createAnnualAccounting!: (params: CreateAnnualAccounting) => Promise<ApiResponse>;
  @ListOfAssets.Action(FETCH_ACCOUNTING) fetchAccounting!: ({ listOfAssetsId, id }: { listOfAssetsId: string, id: string }) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;

  file: string = '';

  value: CreateAnnualAccounting = {
    accounting_id: uuid(),
    list_of_assets_id: this.listOfAssets.id,
    start: this.start || this.listOfAssets.opened,
    end: this.listOfAssets.closed || format(Date.now(), 'yyyy-MM-dd'),
    with_receiver: true
  };

  get download() {
    if (!this.value) {
      return `Rechnungslegung.pdf`;
    }

    return `Rechnungslegung-${this.value.end}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog === true) {
      this.value.start = this.start;

      const end = format(subDays(addYears(this.start, 1), 1), 'yyyy-MM-dd');
      const today = format(Date.now(), 'yyyy-MM-dd');

      this.value.end = this.listOfAssets.closed || (end > today ? today : end);
      this.value.accounting_id = uuid();

      return;
    }

    setTimeout(() => {
      if (this.file !== '') {
        URL.revokeObjectURL(this.file);
      }

      this.error = null;
      this.file = '';
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.createAnnualAccounting(this.value);
  }

  async fetchPDF(accounting: CreateAnnualAccounting) {
    this.loading = true;

    const { content, error } = await this.fetchAccounting({
      listOfAssetsId: accounting.list_of_assets_id,
      id: accounting.accounting_id
    });

    if (content) {
      this.file = URL.createObjectURL(content);
    }

    if (!error) {
      this.error = error;
    }

    this.loading = false;
  }
}
